@tailwind base {}
@tailwind components {}
@tailwind utilities {}

a {
    text-decoration: none;
    color: inherit; }

.claim {
    height: 920px;
    position: relative;
    z-index: 1;

    &__background {
        background-image: linear-gradient(130deg, #7a59cc 15%, #36a7fc);
        transform: skewY(-8deg);
        transform-origin: 0; }

    &__thunderbolt1 {
        left: -300px;
        top: -450px;
        transform: rotate(8deg); }

    &__thunderbolt2 {
        right: -250px;
        top: 0px;
        transform: rotate(5deg) scale(1.2); }

    @media (max-width: 767.98px) {
        height: 510px; } }

.mobile {
    height: 647px;
    width: 305px;
    padding: 12px;
    border-radius: 36px;
    box-shadow: 0 10px 20px rgba(0, 21, 61, 0.11), 0 2px 6px rgba(0, 21, 64, 0.14), 0 -5px 20px rgba(173, 186, 204, 0.25) inset, 0 4px 7px #fff inset;

    .screen {
        border-radius: 28px;
        padding-top: 71px; }

    .screenshot {
        transform: translateY(var(--screenshot)); }

    @media (max-width: 767.98px) {
        width: 150px;
        height: 305px;
        border-radius: 20px; } }

.template1 {
    transform: translateY(var(--template1)); }

.template2 {
    transform: translateY(var(--template2)); }

.mockup {
    transform: translateY(var(--mockup)); }

.circle {
    width: 715px;
    height: 715px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); }

.thunderbolt3 {
    bottom: 150px; }

.monetizacion {
    background-image: linear-gradient(#fff, #f6f9fd); }


/*----------LOADER----------*/

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    align-self: center; }

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #508aea transparent #508aea transparent;
    animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

/*----------------------------------*/
